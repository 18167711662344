import instance from "./instance";
export const order = (values) => {
    return instance.post(`/orders`, values)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
};
export const getOrders = () => {
    return instance.get(`/orders`)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
};
