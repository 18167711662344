import { useEffect, useRef, useState } from "react";
export const useDropdown = () => {
    const dropdownRef = useRef(null);
    const [dropdownActive, setDropdownActive] = useState(false);
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target))
                setDropdownActive(false);
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownActive, dropdownRef]);
    return {
        dropdownRef,
        dropdownActive,
        setDropdownActive
    };
};
