import instance from "./instance";
export const findAll = () => {
    return instance.get(`/documents`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const download = (id) => {
    return instance.get(`documents/download/${id}`, {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/pdf'
        }
    })
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
