import { RealisationsActionTypes } from "./realisationsTypes";
export const fetchRealisationsAttempt = () => ({
    type: RealisationsActionTypes.FETCH_REALISATIONS_ATTEMPT,
});
export const fetchRealisationsSuccess = (data) => ({
    type: RealisationsActionTypes.FETCH_REALISATIONS_SUCCESS,
    payload: data
});
export const fetchRealisationFailure = (errors) => ({
    type: RealisationsActionTypes.FETCH_REALISATIONS_FAILURE,
    payload: errors
});
