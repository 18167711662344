import { DocumentActionTypes } from './documentTypes';
const initialState = {
    list: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DocumentActionTypes.FETCH_DOCUMENTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: action.payload });
        default:
            return state;
    }
};
export { reducer as documentReducer };
