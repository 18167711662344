import { FiltersActionTypes } from "./filtersTypes";
export const fetchFiltersAttempt = () => ({
    type: FiltersActionTypes.FETCH_FILTERS_ATTEMPT,
});
export const fetchFiltersSuccess = (data) => ({
    type: FiltersActionTypes.FETCH_FILTERS_SUCCESS,
    payload: data
});
export const fetchFiltersFailure = (errors) => ({
    type: FiltersActionTypes.FETCH_FILTERS_FAILURE,
    payload: errors
});
