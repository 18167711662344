import { QuotationActionTypes } from "./quotationTypes";
const initialState = {
    quotation: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QuotationActionTypes.QUOTATION_SUCCESS:
            return Object.assign(Object.assign({}, state), action.payload);
        default:
            return state;
    }
};
export { reducer as quotationReducer };
