import { ShippingActionTypes } from "./shippingsTypes";
const initialState = {
    fees: {},
    pudos: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ShippingActionTypes.FETCH_SHIPPING_FEES_SUCCESS:
            return Object.assign(Object.assign({}, state), { fees: action.payload });
        case ShippingActionTypes.FETCH_PUDOS_SUCCESS:
            return Object.assign(Object.assign({}, state), { pudos: action.payload });
        default:
            return state;
    }
};
export { reducer as shippingReducer };
