import instance from './instance';
export const register = (credentials) => {
    return instance.post(`/auth/register`, credentials)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const login = async (credentials) => {
    return instance.post(`/auth/login`, credentials)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const renewPassword = async (infos) => {
    return instance.put(`/auth/renewPassword`, infos)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const forgetPassword = async (email) => {
    return instance.post(`/auth/forgetPassword`, email)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const logout = async () => {
    return instance.get(`/auth/logout`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const authGoogle = (infos) => {
    return instance.post(`/auth/google`, infos)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const authApple = (infos) => {
    return instance.post(`/auth/apple`, infos)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const authFacebook = (infos) => {
    return instance.post(`/auth/facebook`, infos)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
