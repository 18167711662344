import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { VideoActionTypes } from './videoTypes';
import { fetchVideosSuccess, fetchVideosFailure } from './videoActions';
import { extractResponseData, extractResponseErrors } from 'common';
function* fetchVideos() {
    while (true) {
        yield take(VideoActionTypes.FETCH_VIDEOS_ATTEMPT);
        const { response, error } = yield call(api.videos.findAll);
        if (response)
            yield put(fetchVideosSuccess(extractResponseData(response)));
        else
            yield put(fetchVideosFailure(extractResponseErrors(error)));
    }
}
export function* videosSagas() {
    yield all([
        fork(fetchVideos),
    ]);
}
