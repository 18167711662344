import { ProfileActionTypes } from "./profileTypes";
export const fetchProfileAttempt = () => ({
    type: ProfileActionTypes.FETCH_PROFILE_ATTEMPT
});
export const fetchProfileSuccess = (data) => ({
    type: ProfileActionTypes.FETCH_PROFILE_SUCCESS,
    payload: data
});
export const fetchProfileFailure = (errors) => ({
    type: ProfileActionTypes.FETCH_PROFILE_FAILURE,
    payload: errors
});
export const updateProfileAttempt = (infos) => ({
    type: ProfileActionTypes.UPDATE_PROFILE_ATTEMPT,
    payload: infos
});
export const updateProfileSuccess = (data) => ({
    type: ProfileActionTypes.UPDATE_PROFILE_SUCCESS,
    payload: data
});
export const updateProfileFailure = (errors) => ({
    type: ProfileActionTypes.UPDATE_PROFILE_FAILURE,
    payload: errors
});
export const registerProfileAttempt = (credentials) => ({
    type: ProfileActionTypes.REGISTER_PROFILE_ATTEMPT,
    payload: credentials
});
export const registerProfileSuccess = (data) => ({
    type: ProfileActionTypes.REGISTER_PROFILE_SUCCESS,
    payload: data
});
export const registerProfileFailure = (errors) => ({
    type: ProfileActionTypes.REGISTER_PROFILE_FAILURE,
    payload: errors
});
export const forgetPasswordAttempt = (email) => ({
    type: ProfileActionTypes.FORGET_PASSWORD_ATTEMPT,
    payload: email
});
export const forgetPasswordSuccess = (data) => ({
    type: ProfileActionTypes.FORGET_PASSWORD_SUCCESS,
    payload: data
});
export const forgetPasswordFailure = (errors) => ({
    type: ProfileActionTypes.FORGET_PASSWORD_FAILURE,
    payload: errors
});
export const renewPasswordAttempt = (infos) => ({
    type: ProfileActionTypes.RENEW_PASSWORD_ATTEMPT,
    payload: infos
});
export const renewPasswordSuccess = (data) => ({
    type: ProfileActionTypes.RENEW_PASSWORD_SUCCESS,
    payload: data
});
export const renewPasswordFailure = (errors) => ({
    type: ProfileActionTypes.RENEW_PASSWORD_FAILURE,
    payload: errors
});
export const toggleUpdateProfileAnimation = (toggle) => ({
    type: ProfileActionTypes.TOGGLE_UPDATE_PROFILE_ANIMATION,
    payload: toggle
});
export const renewPasswordMessage = (toggle) => ({
    type: ProfileActionTypes.RENEW_PASSWORD_MESSAGE,
    payload: toggle
});
export const changedPasswordMessage = (toggle) => ({
    type: ProfileActionTypes.CHANGED_PASSWORD_MESSAGE,
    payload: toggle
});
export const authGoogleAttempt = (data) => ({
    type: ProfileActionTypes.AUTH_GOOGLE_ATTEMPT,
    payload: data
});
export const authGoogleSuccess = (data) => ({
    type: ProfileActionTypes.AUTH_GOOGLE_SUCCESS,
    payload: data
});
export const authGoogleFailure = (errors) => ({
    type: ProfileActionTypes.AUTH_GOOGLE_FAILURE,
    payload: errors
});
export const authAppleAttempt = (data) => ({
    type: ProfileActionTypes.AUTH_APPLE_ATTEMPT,
    payload: data
});
export const authAppleSuccess = (data) => ({
    type: ProfileActionTypes.AUTH_APPLE_SUCCESS,
    payload: data
});
export const authAppleFailure = (errors) => ({
    type: ProfileActionTypes.AUTH_APPLE_FAILURE,
    payload: errors
});
export const authFacebookAttempt = (data) => ({
    type: ProfileActionTypes.AUTH_FACEBOOK_ATTEMPT,
    payload: data
});
export const authFacebookSuccess = (data) => ({
    type: ProfileActionTypes.AUTH_FACEBOOK_SUCCESS,
    payload: data
});
export const authFacebookFailure = (errors) => ({
    type: ProfileActionTypes.AUTH_FACEBOOK_FAILURE,
    payload: errors
});
