import { extractResponseData, extractResponseErrors } from "common";
import { push } from "connected-react-router";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchContentFailure, fetchContentSuccess } from "./contentsActions";
import { ContentsActionTypes } from "./contentsTypes";
function* fetchContent() {
    while (true) {
        const action = yield take(ContentsActionTypes.FETCH_CONTENT_ATTEMPT);
        const { response, error } = yield call(api.contents.findOne, action.payload);
        if (response) {
            yield put(fetchContentSuccess(extractResponseData(response)));
        }
        else {
            yield put(fetchContentFailure(extractResponseErrors(error)));
            yield put(push('/404'));
        }
    }
}
export function* contentsSagas() {
    yield all([
        fork(fetchContent),
    ]);
}
