import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { extractResponseData, extractResponseErrors } from 'common';
import { FiltersActionTypes } from './filtersTypes';
import { fetchFiltersSuccess, fetchFiltersFailure } from './filtersActions';
function* fetchFilters() {
    while (true) {
        yield take(FiltersActionTypes.FETCH_FILTERS_ATTEMPT);
        const { response, error } = yield call(api.filters.findAll);
        if (response)
            yield put(fetchFiltersSuccess(extractResponseData(response)));
        else
            yield put(fetchFiltersFailure(extractResponseErrors(error)));
    }
}
export function* filtersSagas() {
    yield all([
        fork(fetchFilters),
    ]);
}
