import instance from './instance';
export const create = (data) => {
    return instance.post(`/users/addresses`, data)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const update = (data) => {
    return instance.put(`/users/addresses`, data)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const deleteOne = (id) => {
    return instance.delete(`/users/addresses/${id}`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
