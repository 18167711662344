import instance from './instance';
export const prevalidate = ({ cart, businessType }) => {
    return instance.post(`/orders/prevalidate`, Object.assign(Object.assign({}, cart), { businessType }))
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const verifyPromotion = ({ code, cart }) => {
    return instance.post(`/promotions/${code}`, Object.assign({}, cart))
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const fetchUserPromotion = (cart) => {
    return instance.post(`/promotions`, Object.assign({}, cart))
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
