import instance from "./instance";
export const findAll = () => {
    return instance.get(`/contents`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const findOne = (slug) => {
    return instance.get(`/contents/${slug}`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
