import { all, fork } from 'redux-saga/effects';
import { profileSagas } from './profile/profileSagas';
import { addressSagas } from './addresses/addressSagas';
import { videosSagas } from './videos/videoSagas';
import { documentSagas } from './documents/documentSagas';
import { ordersSagas } from './orders/ordersSagas';
import { quotationsSagas } from './quotations/quotationsSagas';
import { creditsSagas } from './credits/creditsSagas';
export function* accountSagas() {
    yield all([
        fork(profileSagas),
        fork(addressSagas),
        fork(videosSagas),
        fork(documentSagas),
        fork(ordersSagas),
        fork(quotationsSagas),
        fork(creditsSagas),
    ]);
}
