import { QuotationsActionTypes } from "./quotationsTypes";
export const fetchQuotationsAttempt = () => ({
    type: QuotationsActionTypes.FETCH_QUOTATIONS_ATTEMPT,
});
export const fetchQuotationsSuccess = (data) => ({
    type: QuotationsActionTypes.FETCH_QUOTATIONS_SUCCESS,
    payload: data
});
export const fetchQuotationsFailure = (errors) => ({
    type: QuotationsActionTypes.FETCH_QUOTATIONS_FAILURE,
    payload: errors
});
