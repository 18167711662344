import { DocumentActionTypes } from "./documentTypes";
export const fetchDocumentsAttempt = () => ({
    type: DocumentActionTypes.FETCH_DOCUMENTS_ATTEMPT
});
export const fetchDocumentsSuccess = (data) => ({
    type: DocumentActionTypes.FETCH_DOCUMENTS_SUCCESS,
    payload: data
});
export const fetchDocumentsFailure = (errors) => ({
    type: DocumentActionTypes.FETCH_DOCUMENTS_FAILURE,
    payload: errors
});
export const downloadDocumentAttempt = (id) => ({
    type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ATTEMPT,
    payload: id
});
export const downloadDocumentSuccess = (data) => ({
    type: DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
    payload: data
});
export const downloadDocumentFailure = (errors) => ({
    type: DocumentActionTypes.DOWNLOAD_DOCUMENT_FAILURE,
    payload: errors
});
