import { computeCartPrices } from 'common';
import { createSelector } from 'reselect';
import { selectProfileInfos } from 'store/account/profile/profileSelectors';
export const selectCartState = createSelector((state) => state.cart, cart => cart);
export const selectIsPromoOpen = createSelector(selectCartState, cart => cart.isPromoOpen);
export const selectCartProducts = createSelector(selectCartState, cart => cart.products);
export const selectCartPrices = createSelector(selectCartState, selectProfileInfos, (cart, user) => computeCartPrices(cart, user));
export const selectCartFees = createSelector(selectCartState, cart => cart.fees);
export const selectCartPromotion = createSelector(selectCartState, cart => cart.promotion);
export const selectCart = createSelector(selectCartProducts, selectCartFees, selectCartPromotion, selectCartPrices, selectProfileInfos, (products, fees, promotion, { total, totalVat, totalWithPromo, totalVatWithPromo, promoReduction }, profile) => ({
    products, total, totalVat, totalWithPromo, totalVatWithPromo, fees, promotion, promoReduction,
    businessType: profile === null || profile === void 0 ? void 0 : profile.businessType
}));
export const selectProductAddedToCart = createSelector(selectCartState, cart => cart.productAddedToCart);
export const selectProductIndexToModify = createSelector(selectCartState, cart => cart.edit);
