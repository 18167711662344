import { VideoActionTypes, } from "./videoTypes";
export const fetchVideosAttempt = () => ({
    type: VideoActionTypes.FETCH_VIDEOS_ATTEMPT,
});
export const fetchVideosSuccess = (data) => ({
    type: VideoActionTypes.FETCH_VIDEOS_SUCCESS,
    payload: data
});
export const fetchVideosFailure = (errors) => ({
    type: VideoActionTypes.FETCH_VIDEOS_FAILURE,
    payload: errors
});
