import { RealisationsActionTypes } from "./realisationsTypes";
const initialState = {
    list: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RealisationsActionTypes.FETCH_REALISATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: action.payload });
        default:
            return state;
    }
};
export { reducer as realisationsReducer };
