import { ShippingActionTypes } from "./shippingsTypes";
export const fetchShippingFeesAttempt = (data) => ({
    type: ShippingActionTypes.FETCH_SHIPPING_FEES_ATTEMPT,
    payload: data
});
export const fetchShippingFeesSuccess = (data) => ({
    type: ShippingActionTypes.FETCH_SHIPPING_FEES_SUCCESS,
    payload: data
});
export const fetchShippingFeesFailure = (errors) => ({
    type: ShippingActionTypes.FETCH_SHIPPING_FEES_FAILURE,
    payload: errors
});
export const fetchPudosAttempt = (data) => ({
    type: ShippingActionTypes.FETCH_PUDOS_ATTEMPT,
    payload: data
});
export const fetchPudosSuccess = (data) => ({
    type: ShippingActionTypes.FETCH_PUDOS_SUCCESS,
    payload: data
});
export const fetchPudosFailure = (errors) => ({
    type: ShippingActionTypes.FETCH_PUDOS_FAILURE,
    payload: errors
});
