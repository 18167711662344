import { OrderActionTypes } from './orderTypes';
export const orderAttempt = (data) => ({
    type: OrderActionTypes.ORDER_ATTEMPT,
    payload: data
});
export const orderSuccess = (data) => ({
    type: OrderActionTypes.ORDER_SUCCESS,
    payload: data
});
export const orderFailure = (errors) => ({
    type: OrderActionTypes.ORDER_FAILURE,
    payload: errors
});
