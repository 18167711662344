import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchOrdersFailure, fetchOrdersSuccess } from "./ordersActions";
import { OrdersActionTypes } from "./ordersTypes";
function* fetchOrders() {
    while (true) {
        yield take(OrdersActionTypes.FETCH_ORDERS_ATTEMPT);
        const { response, error } = yield call(api.order.getOrders);
        if (response)
            yield put(fetchOrdersSuccess(extractResponseData(response)));
        else
            yield put(fetchOrdersFailure(extractResponseErrors(error)));
    }
}
export function* ordersSagas() {
    yield all([
        fork(fetchOrders),
    ]);
}
