import { combineReducers } from "redux";
import { profileReducer as profile } from "./profile/profileReducer";
import { videoReducer as videos } from "./videos/videoReducers";
import { documentReducer as documents } from "./documents/documentReducer";
import { ordersReducer as orders } from "./orders/ordersReducer";
import { quotationsReducer as quotations } from "./quotations/quotationsReducer";
import { creditsReducer as credits } from "./credits/creditsReducer";
export const accountReducers = combineReducers({
    profile,
    videos,
    documents,
    orders,
    quotations,
    credits,
});
