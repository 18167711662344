import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchHomeFailure, fetchHomeSuccess } from "./homeActions";
import { HomeActionTypes } from "./homeTypes";
function* fetchHome() {
    while (true) {
        const action = yield take(HomeActionTypes.FETCH_HOME_ATTEMPT);
        const { businessType } = action.payload;
        const { response, error } = yield call(api.home.findAll, businessType);
        if (response)
            yield put(fetchHomeSuccess(extractResponseData(response)));
        else
            yield put(fetchHomeFailure(extractResponseErrors(error)));
    }
}
export function* homeSagas() {
    yield all([fork(fetchHome)]);
}
