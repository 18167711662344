var _a;
import axios from 'axios';
import { isBrowser } from 'common';
const instance = axios.create({
    baseURL: isBrowser() ? `${(_a = window.__env__) === null || _a === void 0 ? void 0 : _a.RAZZLE_API_URL}` : `${process.env.RAZZLE_SSR_API_URL}`,
    withCredentials: true,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});
export default instance;
