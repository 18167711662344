import { ContentsActionTypes } from "./contentsTypes";
export const fetchContentAttempt = (slug) => ({
    type: ContentsActionTypes.FETCH_CONTENT_ATTEMPT,
    payload: slug
});
export const fetchContentSuccess = (data) => ({
    type: ContentsActionTypes.FETCH_CONTENT_SUCCESS,
    payload: data
});
export const fetchContentFailure = (errors) => ({
    type: ContentsActionTypes.FETCH_CONTENT_FAILURE,
    payload: errors
});
