import { fetchProfileSuccess, fetchProfileFailure, updateProfileSuccess, updateProfileFailure, registerProfileFailure, registerProfileSuccess, renewPasswordSuccess, renewPasswordFailure, forgetPasswordSuccess, forgetPasswordFailure, toggleUpdateProfileAnimation, renewPasswordMessage, changedPasswordMessage, authGoogleSuccess, authGoogleFailure, authAppleFailure, authAppleSuccess, authFacebookSuccess, authFacebookFailure } from './profileActions';
import { call, put, take, fork, all, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { ProfileActionTypes } from './profileTypes';
import { extractResponseData, extractResponseErrors, loginAttempt } from 'common';
import { END, eventChannel } from 'redux-saga';
import { push } from 'connected-react-router';
function* countdown(secs) {
    return eventChannel(emitter => {
        const iv = setInterval(() => {
            secs -= 1;
            secs > 0 ? emitter(secs) : emitter(END);
        }, 1000);
        return () => {
            clearInterval(iv);
        };
    });
}
function* startCountdown() {
    const chan = yield call(countdown, 3);
    yield put(toggleUpdateProfileAnimation(true));
    try {
        while (true) {
            yield take(chan);
        }
    }
    finally {
        yield put(toggleUpdateProfileAnimation(false));
    }
}
function* register() {
    while (true) {
        const action = yield take(ProfileActionTypes.REGISTER_PROFILE_ATTEMPT);
        const { response, error } = yield call(api.auth.register, action.payload);
        if (response) {
            yield put(registerProfileSuccess(extractResponseData(response)));
            yield put(loginAttempt({
                email: action.payload.email,
                password: action.payload.password,
            }));
        }
        else
            yield put(registerProfileFailure(extractResponseErrors(error)));
    }
}
function* renewPassword() {
    while (true) {
        const action = yield take(ProfileActionTypes.RENEW_PASSWORD_ATTEMPT);
        const { response, error } = yield call(api.auth.renewPassword, action.payload);
        if (response) {
            yield put(renewPasswordSuccess(extractResponseData(response)));
            yield put(changedPasswordMessage(true));
        }
        else {
            yield put(renewPasswordFailure(extractResponseErrors(error)));
        }
    }
}
function* forgetPassword() {
    while (true) {
        const action = yield take(ProfileActionTypes.FORGET_PASSWORD_ATTEMPT);
        const { response, error } = yield call(api.auth.forgetPassword, action.payload);
        if (response) {
            yield put(forgetPasswordSuccess(extractResponseData(response)));
            yield put(renewPasswordMessage(true));
        }
        else {
            yield put(forgetPasswordFailure(extractResponseErrors(error)));
        }
    }
}
function* fetchProfile() {
    while (true) {
        yield take(ProfileActionTypes.FETCH_PROFILE_ATTEMPT);
        const { response, error } = yield call(api.users.me);
        if (response)
            yield put(fetchProfileSuccess(extractResponseData(response)));
        else {
            yield put(fetchProfileFailure(extractResponseErrors(error)));
        }
    }
}
function* updateProfile() {
    while (true) {
        const action = yield take(ProfileActionTypes.UPDATE_PROFILE_ATTEMPT);
        const { response, error } = yield call(api.users.updateProfile, action.payload);
        if (response) {
            yield put(updateProfileSuccess(extractResponseData(response)));
            yield takeLatest(ProfileActionTypes.UPDATE_PROFILE_SUCCESS, startCountdown);
        }
        else {
            yield put(updateProfileFailure(extractResponseErrors(error)));
        }
    }
}
function* googleLogin() {
    while (true) {
        const action = yield take(ProfileActionTypes.AUTH_GOOGLE_ATTEMPT);
        const { response, error } = yield call(api.auth.authGoogle, action.payload);
        if (response) {
            yield put(authGoogleSuccess(extractResponseData(response)));
            if (response.status === 200)
                yield put(push('/nos-produits'));
        }
        else {
            yield put(authGoogleFailure(extractResponseErrors(error)));
        }
    }
}
function* appleLogin() {
    while (true) {
        const action = yield take(ProfileActionTypes.AUTH_APPLE_ATTEMPT);
        const { response, error } = yield call(api.auth.authApple, action.payload);
        if (response) {
            yield put(authAppleSuccess(extractResponseData(response)));
            if (response.status === 200)
                yield put(push('/nos-produits'));
        }
        else {
            yield put(authAppleFailure(extractResponseErrors(error)));
        }
    }
}
function* facebookLogin() {
    while (true) {
        const action = yield take(ProfileActionTypes.AUTH_FACEBOOK_ATTEMPT);
        const { response, error } = yield call(api.auth.authFacebook, action.payload);
        if (response) {
            yield put(authFacebookSuccess(extractResponseData(response)));
            if (response.status === 200)
                yield put(push('/nos-produits'));
        }
        else {
            yield put(authFacebookFailure(extractResponseErrors(error)));
        }
    }
}
export function* profileSagas() {
    yield all([
        fork(fetchProfile),
        fork(updateProfile),
        fork(renewPassword),
        fork(register),
        fork(forgetPassword),
        fork(googleLogin),
        fork(appleLogin),
        fork(facebookLogin),
    ]);
}
