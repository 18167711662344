import { call, put, take } from "@redux-saga/core/effects";
import { extractResponseData, extractResponseErrors } from "common";
import { all, fork } from "redux-saga/effects";
import api from "services/api";
import { fetchCreditsFailure, fetchCreditsSuccess } from "./creditsActions";
import { CreditsActionTypes } from "./creditsTypes";
function* fetchCredits() {
    while (true) {
        yield take(CreditsActionTypes.FETCH_CREDITS_ATTEMPT);
        const { response, error } = yield call(api.credits.getCredits);
        if (response)
            yield put(fetchCreditsSuccess(extractResponseData(response)));
        else
            yield put(fetchCreditsFailure(extractResponseErrors(error)));
    }
}
export function* creditsSagas() {
    yield all([
        fork(fetchCredits),
    ]);
}
