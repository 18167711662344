import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import './NotFound.scss';
function NotFound() {
    return (React.createElement("div", { className: "container not-found" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "404 - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("h1", null, "404"),
        React.createElement("h1", null,
            React.createElement(FormattedMessage, { id: "La page demand\u00E9e n'a pas \u00E9t\u00E9 retrouv\u00E9e", defaultMessage: "La page demand\u00E9e n'a pas \u00E9t\u00E9 retrouv\u00E9e" }))));
}
export default NotFound;
