import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import { answerReducer } from './answers/answerReducer';
import { productReducer } from './products/productReducer';
import { cartReducer } from './cart/cartReducer';
import { metaStateReducer, authReducer } from "common";
import { tagReducer } from "./tags/tagReducer";
import { modalReducer } from "./modal/modalReducer";
import { accountReducers } from "./account/accountReducers";
import { shippingReducer } from "./shippings/shippingsReducer";
import { orderReducer } from "./order/orderReducer";
import { homeReducer } from "./home/homeReducers";
import { realisationsReducer } from "./realisations/realisationsReducer";
import { contentsReducer } from "./contents/contentsReducer";
import { colorChartsReducer } from "./colorCharts/colorChartsReducers";
import { bannerReducer } from "./banner/bannerReducer";
import { quotationReducer } from "./quotation/quotationReducer";
import { filtersReducer } from "./filters/filtersReducer";
import { translationReducer } from "./translations/translationsReducer";
export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    metaState: metaStateReducer,
    account: accountReducers,
    answers: answerReducer,
    products: productReducer,
    cart: cartReducer,
    tags: tagReducer,
    modals: modalReducer,
    shippings: shippingReducer,
    order: orderReducer,
    home: homeReducer,
    realisations: realisationsReducer,
    contents: contentsReducer,
    colorCharts: colorChartsReducer,
    banner: bannerReducer,
    quotation: quotationReducer,
    filters: filtersReducer,
    translations: translationReducer,
});
export default rootReducer;
