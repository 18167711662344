import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { downloadDocumentFailure, downloadDocumentSuccess, fetchDocumentsFailure, fetchDocumentsSuccess } from "./documentActions";
import { DocumentActionTypes } from "./documentTypes";
import { saveAs } from "file-saver";
function* fetchDocuments() {
    while (true) {
        yield take(DocumentActionTypes.FETCH_DOCUMENTS_ATTEMPT);
        const { response, error } = yield call(api.documents.findAll);
        if (response)
            yield put(fetchDocumentsSuccess(extractResponseData(response)));
        else
            yield put(fetchDocumentsFailure(extractResponseErrors(error)));
    }
}
function* downloadDocument() {
    while (true) {
        const action = yield take(DocumentActionTypes.DOWNLOAD_DOCUMENT_ATTEMPT);
        const { response, error } = yield call(api.documents.download, action.payload);
        if (response) {
            const blob = yield call((chunk) => {
                return new Blob([extractResponseData(chunk)], { type: "application/pdf;charset=utf-8" });
            }, response);
            yield call(saveAs, blob, 'document.pdf');
            yield put(downloadDocumentSuccess(extractResponseData(response)));
        }
        else
            yield put(downloadDocumentFailure(extractResponseErrors(error)));
    }
}
export function* documentSagas() {
    yield all([
        fork(fetchDocuments),
        fork(downloadDocument),
    ]);
}
