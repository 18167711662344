import { ColorChartsActionTypes } from "./colorChartsTypes";
export const fetchColorChartsAttempt = () => ({
    type: ColorChartsActionTypes.FETCH_COLORCHARTS_ATTEMPT,
});
export const fetchColorChartsSuccess = (data) => ({
    type: ColorChartsActionTypes.FETCH_COLORCHARTS_SUCCESS,
    payload: data
});
export const fetchColorChartsFailure = (errors) => ({
    type: ColorChartsActionTypes.FETCH_COLORCHARTS_FAILURE,
    payload: errors
});
