import instance from './instance';
export const me = () => {
    return instance.get(`/users/me`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const updateProfile = (infos) => {
    return instance.put(`/users`, infos)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
