import { ProductActionTypes } from "./productTypes";
export const fetchProductsAttempt = () => ({
    type: ProductActionTypes.FETCH_PRODUCTS_ATTEMPT
});
export const fetchProductsSuccess = (data) => ({
    type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: data
});
export const fetchProductsFailure = (errors) => ({
    type: ProductActionTypes.FETCH_PRODUCTS_FAILURE,
    payload: errors
});
export const fetchProductAttempt = (slug) => ({
    type: ProductActionTypes.FETCH_PRODUCT_ATTEMPT,
    payload: slug
});
export const fetchProductSuccess = (data) => ({
    type: ProductActionTypes.FETCH_PRODUCT_SUCCESS,
    payload: data
});
export const fetchProductFailure = (errors) => ({
    type: ProductActionTypes.FETCH_PRODUCT_FAILURE,
    payload: errors
});
export const fetchColorChartAttempt = () => ({
    type: ProductActionTypes.FETCH_COLOR_CHART_ATTEMPT
});
export const fetchColorChartSuccess = (data) => ({
    type: ProductActionTypes.FETCH_COLOR_CHART_SUCCESS,
    payload: data
});
export const fetchColorChartFailure = (errors) => ({
    type: ProductActionTypes.FETCH_COLOR_CHART_FAILURE,
    payload: errors
});
