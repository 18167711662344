export var AddressActionTypes;
(function (AddressActionTypes) {
    AddressActionTypes["UPDATE_ADDRESS_ATTEMPT"] = "UPDATE_ADDRESS_ATTEMPT";
    AddressActionTypes["UPDATE_ADDRESS_SUCCESS"] = "UPDATE_ADDRESS_SUCCESS";
    AddressActionTypes["UPDATE_ADDRESS_FAILURE"] = "UPDATE_ADDRESS_FAILURE";
    AddressActionTypes["CREATE_ADDRESS_ATTEMPT"] = "CREATE_ADDRESS_ATTEMPT";
    AddressActionTypes["CREATE_ADDRESS_SUCCESS"] = "CREATE_ADDRESS_SUCCESS";
    AddressActionTypes["CREATE_ADDRESS_FAILURE"] = "CREATE_ADDRESS_FAILURE";
    AddressActionTypes["DELETE_ADDRESS_ATTEMPT"] = "DELETE_ADDRESS_ATTEMPT";
    AddressActionTypes["DELETE_ADDRESS_SUCCESS"] = "DELETE_ADDRESS_SUCCESS";
    AddressActionTypes["DELETE_ADDRESS_FAILURE"] = "DELETE_ADDRESS_FAILURE";
    AddressActionTypes["TOGGLE_UPDATE_ADDRESS_ANIMATION"] = "TOGGLE_UPDATE_ADDRESS_ANIMATION";
})(AddressActionTypes || (AddressActionTypes = {}));
