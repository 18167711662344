import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchBannerFailure, fetchBannerSuccess } from "./bannerActions";
import { BannerActionTypes } from "./bannerTypes";
function* fetchBanner() {
    while (true) {
        yield take(BannerActionTypes.FETCH_BANNER_ATTEMPT);
        const { response, error } = yield call(api.banner.findAll);
        if (response)
            yield put(fetchBannerSuccess(extractResponseData(response)));
        else
            yield put(fetchBannerFailure(extractResponseErrors(error)));
    }
}
export function* bannerSagas() {
    yield all([
        fork(fetchBanner),
    ]);
}
