import { ContentsActionTypes } from "./contentsTypes";
const initialState = {
    list: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ContentsActionTypes.FETCH_CONTENT_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: [...state.list
                        .filter(content => content._id !== action.payload._id),
                    action.payload] });
        default:
            return state;
    }
};
export { reducer as contentsReducer };
