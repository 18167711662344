import { ColorChartsActionTypes } from "./colorChartsTypes";
const initialState = {
    infos: {}
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ColorChartsActionTypes.FETCH_COLORCHARTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { infos: action.payload });
        default:
            return state;
    }
};
export { reducer as colorChartsReducer };
