export var ProfileActionTypes;
(function (ProfileActionTypes) {
    ProfileActionTypes["FETCH_PROFILE_ATTEMPT"] = "FETCH_PROFILE_ATTEMPT";
    ProfileActionTypes["FETCH_PROFILE_SUCCESS"] = "FETCH_PROFILE_SUCCESS";
    ProfileActionTypes["FETCH_PROFILE_FAILURE"] = "FETCH_PROFILE_FAILURE";
    ProfileActionTypes["UPDATE_PROFILE_ATTEMPT"] = "UPDATE_PROFILE_ATTEMPT";
    ProfileActionTypes["UPDATE_PROFILE_SUCCESS"] = "UPDATE_PROFILE_SUCCESS";
    ProfileActionTypes["UPDATE_PROFILE_FAILURE"] = "UPDATE_PROFILE_FAILURE";
    ProfileActionTypes["REGISTER_PROFILE_ATTEMPT"] = "REGISTER_PROFILE_ATTEMPT";
    ProfileActionTypes["REGISTER_PROFILE_SUCCESS"] = "REGISTER_PROFILE_SUCCESS";
    ProfileActionTypes["REGISTER_PROFILE_FAILURE"] = "REGISTER_PROFILE_FAILURE";
    ProfileActionTypes["RENEW_PASSWORD_ATTEMPT"] = "RENEW_PASSWORD_ATTEMPT";
    ProfileActionTypes["RENEW_PASSWORD_SUCCESS"] = "RENEW_PASSWORD_SUCCESS";
    ProfileActionTypes["RENEW_PASSWORD_FAILURE"] = "RENEW_PASSWORD_FAILURE";
    ProfileActionTypes["FORGET_PASSWORD_ATTEMPT"] = "FORGET_PASSWORD_ATTEMPT";
    ProfileActionTypes["FORGET_PASSWORD_SUCCESS"] = "FORGET_PASSWORD_SUCCESS";
    ProfileActionTypes["FORGET_PASSWORD_FAILURE"] = "FORGET_PASSWORD_FAILURE";
    ProfileActionTypes["TOGGLE_UPDATE_PROFILE_ANIMATION"] = "TOGGLE_UPDATE_PROFILE_ANIMATION";
    ProfileActionTypes["RENEW_PASSWORD_MESSAGE"] = "RENEW_PASSWORD_MESSAGE";
    ProfileActionTypes["CHANGED_PASSWORD_MESSAGE"] = "CHANGED_PASSWORD_MESSAGE";
    ProfileActionTypes["AUTH_GOOGLE_ATTEMPT"] = "AUTH_GOOGLE_ATTEMPT";
    ProfileActionTypes["AUTH_GOOGLE_SUCCESS"] = "AUTH_GOOGLE_SUCCESS";
    ProfileActionTypes["AUTH_GOOGLE_FAILURE"] = "AUTH_GOOGLE_FAILURE";
    ProfileActionTypes["AUTH_APPLE_ATTEMPT"] = "AUTH_APPLE_ATTEMPT";
    ProfileActionTypes["AUTH_APPLE_SUCCESS"] = "AUTH_APPLE_SUCCESS";
    ProfileActionTypes["AUTH_APPLE_FAILURE"] = "AUTH_APPLE_FAILURE";
    ProfileActionTypes["AUTH_FACEBOOK_ATTEMPT"] = "AUTH_FACEBOOK_ATTEMPT";
    ProfileActionTypes["AUTH_FACEBOOK_SUCCESS"] = "AUTH_FACEBOOK_SUCCESS";
    ProfileActionTypes["AUTH_FACEBOOK_FAILURE"] = "AUTH_FACEBOOK_FAILURE";
})(ProfileActionTypes || (ProfileActionTypes = {}));
