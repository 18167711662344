import { BannerActionTypes } from "./bannerTypes";
export const fetchBannerAttempt = () => ({
    type: BannerActionTypes.FETCH_BANNER_ATTEMPT,
});
export const fetchBannerSuccess = (data) => ({
    type: BannerActionTypes.FETCH_BANNER_SUCCESS,
    payload: data
});
export const fetchBannerFailure = (errors) => ({
    type: BannerActionTypes.FETCH_BANNER_FAILURE,
    payload: errors
});
