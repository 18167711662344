import { QuotationActionTypes } from "./quotationTypes";
export const quotationAttempt = (data) => ({
    type: QuotationActionTypes.QUOTATION_ATTEMPT,
    payload: data
});
export const quotationSuccess = (data) => ({
    type: QuotationActionTypes.QUOTATION_SUCCESS,
    payload: data
});
export const quotationFailure = (errors) => ({
    type: QuotationActionTypes.QUOTATION_FAILURE,
    payload: errors
});
