import { AddressActionTypes, } from "./addressTypes";
export const createAddressAttempt = (data, callback) => ({
    type: AddressActionTypes.CREATE_ADDRESS_ATTEMPT,
    payload: data,
    callback
});
export const createAddressSuccess = (data) => ({
    type: AddressActionTypes.CREATE_ADDRESS_SUCCESS,
    payload: data
});
export const createAddressFailure = (errors) => ({
    type: AddressActionTypes.CREATE_ADDRESS_FAILURE,
    payload: errors
});
export const updateAddressAttempt = (data) => ({
    type: AddressActionTypes.UPDATE_ADDRESS_ATTEMPT,
    payload: data
});
export const updateAddressSuccess = (data) => ({
    type: AddressActionTypes.UPDATE_ADDRESS_SUCCESS,
    payload: data
});
export const updateAddressFailure = (errors) => ({
    type: AddressActionTypes.UPDATE_ADDRESS_FAILURE,
    payload: errors
});
export const deleteAddressAttempt = (data) => ({
    type: AddressActionTypes.DELETE_ADDRESS_ATTEMPT,
    payload: data
});
export const deleteAddressSuccess = (data) => ({
    type: AddressActionTypes.DELETE_ADDRESS_SUCCESS,
    payload: data
});
export const deleteAddressFailure = (errors) => ({
    type: AddressActionTypes.DELETE_ADDRESS_FAILURE,
    payload: errors
});
export const toggleAddressUpdatedAnimation = (data) => ({
    type: AddressActionTypes.TOGGLE_UPDATE_ADDRESS_ANIMATION,
    payload: data
});
