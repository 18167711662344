import { ModalsActionTypes } from "./modalTypes";
const initialState = {
    modals: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ModalsActionTypes.REGISTER_MODAL:
            return Object.assign(Object.assign({}, state), { modals: [...state.modals, { id: action.payload, isOpen: false }] });
        case ModalsActionTypes.UNREGISTER_MODAL:
            return Object.assign(Object.assign({}, state), { modals: state.modals
                    .filter((modal) => modal.id !== action.payload) });
        case ModalsActionTypes.TOGGLE_MODAL:
            return Object.assign(Object.assign({}, state), { modals: state.modals
                    .map((modal) => {
                    const isOpen = modal.id === action.payload ? true : modal.isOpen;
                    return Object.assign(Object.assign({}, modal), { isOpen });
                }) });
        case ModalsActionTypes.CLOSE_MODAL:
            return Object.assign(Object.assign({}, state), { modals: state.modals
                    .map((modal) => {
                    const isOpen = modal.id === action.payload ? false : modal.isOpen;
                    return Object.assign(Object.assign({}, modal), { isOpen });
                }) });
        default:
            return state;
    }
};
export { reducer as modalReducer };
