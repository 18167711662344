import { createSelector } from 'reselect';
import { selectAccountState } from '../accountSelectors';
export const selectProfileState = createSelector(selectAccountState, account => account.profile);
export const selectProfileInfos = createSelector(selectProfileState, profile => profile.infos);
export const selectAddresses = createSelector(selectProfileInfos, infos => (infos === null || infos === void 0 ? void 0 : infos.addresses) || []);
export const selectAddressUpdated = createSelector(selectProfileState, profile => profile.addressUpdated);
export const selectTrainings = createSelector(selectProfileInfos, infos => (infos === null || infos === void 0 ? void 0 : infos.trainings) || []);
export const selectProfileUpdated = createSelector(selectProfileState, profile => profile.profileUpdated);
export const selectRenewedPassword = createSelector(selectProfileState, profile => profile.renewPassword);
export const selectChangedPassword = createSelector(selectProfileState, profile => profile.changedPassword);
export const selectSocials = createSelector(selectProfileState, profile => profile.socials);
