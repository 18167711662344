import { useEffect, useMemo } from 'react';
import { debounce } from 'shared/helpers';
export const useYotpoRefresh = (debounceTime = 200, reviewId) => {
    const debouncedRefresh = useMemo(() => {
        const refreshWidgets = typeof window !== 'undefined' && window.yotpo
            ? window.yotpo.refreshWidgets.bind(window.yotpo)
            : () => { console.log(); };
        return debounce(refreshWidgets, debounceTime);
    }, [debounceTime]);
    useEffect(() => {
        if (typeof window !== 'undefined' && window.yotpo) {
            debouncedRefresh();
        }
    }, [debouncedRefresh, reviewId]);
};
