import { AddressActionTypes } from '../addresses/addressTypes';
import { ProfileActionTypes } from './profileTypes';
const initialState = {
    infos: null,
    profileUpdated: false,
    addressUpdated: false,
    renewPassword: false,
    changedPassword: false,
    socials: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ProfileActionTypes.FETCH_PROFILE_SUCCESS:
        case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
        case AddressActionTypes.CREATE_ADDRESS_SUCCESS:
        case AddressActionTypes.UPDATE_ADDRESS_SUCCESS:
        case AddressActionTypes.DELETE_ADDRESS_SUCCESS:
            return Object.assign(Object.assign({}, state), { infos: action.payload });
        case ProfileActionTypes.TOGGLE_UPDATE_PROFILE_ANIMATION:
            return Object.assign(Object.assign({}, state), { profileUpdated: action.payload });
        case AddressActionTypes.TOGGLE_UPDATE_ADDRESS_ANIMATION:
            return Object.assign(Object.assign({}, state), { addressUpdated: action.payload });
        case ProfileActionTypes.RENEW_PASSWORD_MESSAGE:
            return Object.assign(Object.assign({}, state), { renewPassword: action.payload });
        case ProfileActionTypes.CHANGED_PASSWORD_MESSAGE:
            return Object.assign(Object.assign({}, state), { changedPassword: action.payload });
        case ProfileActionTypes.AUTH_APPLE_SUCCESS:
            return Object.assign(Object.assign({}, state), { socials: {
                    email: action.payload.email
                } });
        case ProfileActionTypes.AUTH_GOOGLE_SUCCESS:
            return Object.assign(Object.assign({}, state), { socials: {
                    firstname: action.payload.given_name,
                    lastname: action.payload.family_name,
                    email: action.payload.email
                } });
        case ProfileActionTypes.AUTH_FACEBOOK_SUCCESS:
            return Object.assign(Object.assign({}, state), { socials: {
                    firstname: action.payload.first_name,
                    lastname: action.payload.last_name,
                    email: action.payload.email
                } });
        default:
            return state;
    }
};
export { reducer as profileReducer };
