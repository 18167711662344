import { createAddressSuccess, createAddressFailure, updateAddressFailure, updateAddressSuccess, deleteAddressSuccess, deleteAddressFailure, toggleAddressUpdatedAnimation } from './addressActions';
import { call, put, take, fork, all, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { AddressActionTypes } from './addressTypes';
import { extractResponseData, extractResponseErrors } from 'common';
import { END, eventChannel } from 'redux-saga';
function countdown(secs) {
    return eventChannel(emitter => {
        const iv = setInterval(() => {
            secs -= 1;
            secs > 0 ? emitter(secs) : emitter(END);
        }, 1000);
        return () => {
            clearInterval(iv);
        };
    });
}
function* startCountdown() {
    const chan = yield call(countdown, 2);
    yield put(toggleAddressUpdatedAnimation(true));
    try {
        while (true) {
            yield take(chan);
        }
    }
    finally {
        yield put(toggleAddressUpdatedAnimation(false));
    }
}
function* createAddress() {
    var _a;
    while (true) {
        const action = yield take(AddressActionTypes.CREATE_ADDRESS_ATTEMPT);
        const { response, error } = yield call(api.addresses.create, action.payload);
        if (response) {
            const data = extractResponseData(response);
            yield put(createAddressSuccess(data));
            if (action === null || action === void 0 ? void 0 : action.callback)
                yield call(action.callback, null, ((_a = data === null || data === void 0 ? void 0 : data.addresses) === null || _a === void 0 ? void 0 : _a.slice(-1)[0]) || null);
        }
        else {
            const err = extractResponseErrors(error);
            yield put(createAddressFailure(err));
            if (action === null || action === void 0 ? void 0 : action.callback)
                yield call(action.callback, err, null);
        }
    }
}
function* deleteAddress() {
    while (true) {
        const action = yield take(AddressActionTypes.DELETE_ADDRESS_ATTEMPT);
        const { response, error } = yield call(api.addresses.deleteOne, action.payload);
        if (response)
            yield put(deleteAddressSuccess(extractResponseData(response)));
        else {
            yield put(deleteAddressFailure(extractResponseErrors(error)));
        }
    }
}
function* updateAddress() {
    while (true) {
        const action = yield take(AddressActionTypes.UPDATE_ADDRESS_ATTEMPT);
        const { response, error } = yield call(api.addresses.update, action.payload);
        if (response) {
            yield put(updateAddressSuccess(extractResponseData(response)));
            yield takeLatest(AddressActionTypes.UPDATE_ADDRESS_SUCCESS, startCountdown);
        }
        else {
            yield put(updateAddressFailure(extractResponseErrors(error)));
        }
    }
}
export function* addressSagas() {
    yield all([
        fork(createAddress),
        fork(deleteAddress),
        fork(updateAddress),
    ]);
}
