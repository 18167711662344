import { all, call, put, take } from "@redux-saga/core/effects";
import { extractResponseData, extractResponseErrors } from "common";
import { push } from "connected-react-router";
import { fork } from "redux-saga/effects";
import api from "services/api";
import { resetCart } from "store/cart/cartActions";
import { quotationFailure, quotationSuccess } from "./quotationActions";
import { QuotationActionTypes } from "./quotationTypes";
function* quotationSaga() {
    while (true) {
        const action = yield take(QuotationActionTypes.QUOTATION_ATTEMPT);
        const { response, error } = yield call(api.quotation.quotation, action.payload);
        if (response) {
            yield put(quotationSuccess(extractResponseData(response)));
            yield put(resetCart());
            yield put(push(`/devis/succes`));
        }
        else {
            yield put(quotationFailure(extractResponseErrors(error)));
            yield put(push(`/devis/echec`));
        }
    }
}
export function* quotationSagas() {
    yield all([
        fork(quotationSaga),
    ]);
}
