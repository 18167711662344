import React from "react";
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Angle.scss';
export function Angle({ disabled, isActive, setActiveStep, step }) {
    return (React.createElement(FontAwesomeIcon, { className: "angle", onClick: () => {
            if (!disabled && setActiveStep)
                setActiveStep(step);
        }, size: "lg", icon: isActive ? faAngleDown : faAngleUp }));
}
