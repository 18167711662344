import { TranslationsActionTypes } from "./translationsTypes";
export const fetchTranslationAttempt = (language) => ({
    type: TranslationsActionTypes.FETCH_TRANSLATION_ATTEMPT,
    payload: language
});
export const fetchTranslationsSuccess = (data) => ({
    type: TranslationsActionTypes.FETCH_TRANSLATION_SUCCESS,
    payload: data
});
export const fetchTranslationsFailure = (errors) => ({
    type: TranslationsActionTypes.FETCH_TRANSLATION_FAILURE,
    payload: errors
});
