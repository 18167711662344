import { all, fork } from "redux-saga/effects";
import { answersSagas } from './answers/answerSagas';
import { sync, handleExpired, authSagas } from 'common';
import api from "services/api";
import { productsSagas } from "./products/productSagas";
import { accountSagas } from "./account/accountSagas";
import { tagsSagas } from "./tags/tagSagas";
import { cartSagas } from "./cart/cartSagas";
import { shippingsSagas } from "./shippings/shippingsSagas";
import { orderSagas } from "./order/orderSagas";
import { homeSagas } from "./home/homeSagas";
import { realisationsSagas } from "./realisations/realisationsSagas";
import { contentsSagas } from "./contents/contentsSagas";
import { colorChartsSagas } from "./colorCharts/colorChartsSagas";
import { bannerSagas } from "./banner/bannerSagas";
import { quotationSagas } from "./quotation/quotationSagas";
import { filtersSagas } from "./filters/filtersSagas";
import { translationsSagas } from "./translations/translationsSagas";
const authSagaOptions = {
    logoutUrl: '/authentification',
    logoutMethod: api.auth.logout,
    loginUrl: '/nos-produits',
    loginMethod: api.auth.login
};
const keys = ['auth', 'cart', 'order'];
export function* rootSaga() {
    yield all([
        fork(handleExpired({ keys })),
        fork(sync({ keys })),
        fork(authSagas(authSagaOptions)),
        fork(accountSagas),
        fork(answersSagas),
        fork(productsSagas),
        fork(tagsSagas),
        fork(cartSagas),
        fork(shippingsSagas),
        fork(orderSagas),
        fork(homeSagas),
        fork(realisationsSagas),
        fork(contentsSagas),
        fork(colorChartsSagas),
        fork(bannerSagas),
        fork(quotationSagas),
        fork(filtersSagas),
        fork(translationsSagas),
    ]);
}
