export var ProductActionTypes;
(function (ProductActionTypes) {
    ProductActionTypes["FETCH_PRODUCTS_ATTEMPT"] = "FETCH_PRODUCTS_ATTEMPT";
    ProductActionTypes["FETCH_PRODUCTS_SUCCESS"] = "FETCH_PRODUCTS_SUCCESS";
    ProductActionTypes["FETCH_PRODUCTS_FAILURE"] = "FETCH_PRODUCTS_FAILURE";
    ProductActionTypes["FETCH_PRODUCT_ATTEMPT"] = "FETCH_PRODUCT_ATTEMPT";
    ProductActionTypes["FETCH_PRODUCT_SUCCESS"] = "FETCH_PRODUCT_SUCCESS";
    ProductActionTypes["FETCH_PRODUCT_FAILURE"] = "FETCH_PRODUCT_FAILURE";
    ProductActionTypes["FETCH_COLOR_CHART_ATTEMPT"] = "FETCH_COLOR_CHART_ATTEMPT";
    ProductActionTypes["FETCH_COLOR_CHART_SUCCESS"] = "FETCH_COLOR_CHART_SUCCESS";
    ProductActionTypes["FETCH_COLOR_CHART_FAILURE"] = "FETCH_COLOR_CHART_FAILURE";
})(ProductActionTypes || (ProductActionTypes = {}));
