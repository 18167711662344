import { CreditsActionTypes } from "./creditsTypes";
export const fetchCreditsAttempt = () => ({
    type: CreditsActionTypes.FETCH_CREDITS_ATTEMPT,
});
export const fetchCreditsSuccess = (data) => ({
    type: CreditsActionTypes.FETCH_CREDITS_SUCCESS,
    payload: data
});
export const fetchCreditsFailure = (errors) => ({
    type: CreditsActionTypes.FETCH_CREDITS_FAILURE,
    payload: errors
});
