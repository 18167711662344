import { extractResponseData, extractResponseErrors, PaymentMode } from 'common';
import { push } from 'connected-react-router';
import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { resetCart } from 'store/cart/cartActions';
import { orderFailure, orderSuccess } from './orderActions';
import { OrderActionTypes } from './orderTypes';
function* orderSaga() {
    while (true) {
        const action = yield take(OrderActionTypes.ORDER_ATTEMPT);
        const { response, error } = yield call(api.order.order, action.payload);
        if (response) {
            yield put(orderSuccess(extractResponseData(response)));
            const { order } = response.data;
            if ((order === null || order === void 0 ? void 0 : order.paymentMode) === PaymentMode.TRANSFER) {
                yield put(resetCart());
                yield put(push(`/commande/succes`));
            }
        }
        else {
            yield put(orderFailure(extractResponseErrors(error)));
            yield put(push(`/commande/echec`));
        }
    }
}
export function* orderSagas() {
    yield all([
        fork(orderSaga)
    ]);
}
