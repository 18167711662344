import React from "react";
import { Input, InputError } from "components/Form";
import Button from "components/Form/Buttons/Button";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectErrors, selectPending } from "common";
import { forgetPasswordAttempt } from "store/account/profile/profileActions";
import { selectRenewedPassword } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Initialize() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const apiErrors = useSelector(selectErrors('FORGET_PASSWORD'));
    const pending = useSelector(selectPending('FORGET_PASSWORD'));
    const passwordRenewed = useSelector(selectRenewedPassword);
    const onSubmit = (email) => {
        dispatch(forgetPasswordAttempt(email));
    };
    return (React.createElement("div", { className: "login" },
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                email: '',
            }, enableReinitialize: true, onSubmit: onSubmit },
            React.createElement(Form, { className: "has-text-centered-desktop" },
                React.createElement("h1", { style: { color: 'black' } },
                    React.createElement(FormattedMessage, { id: "Mot de passe oubli\u00E9 ?", defaultMessage: "Mot de passe oubli\u00E9 ?" })),
                React.createElement("small", null,
                    React.createElement(FormattedMessage, { id: "Renseignez votre email et v\u00E9rifiez votre bo\u00EEte de r\u00E9ception :", defaultMessage: "Renseignez votre email et v\u00E9rifiez votre bo\u00EEte de r\u00E9ception :" })),
                React.createElement(Input, { name: "email", label: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }), color: "white" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email }),
                React.createElement("br", null),
                React.createElement(Button, { loading: pending, className: "k-btn", buttonName: intl.formatMessage({ id: "Réinitialiser", defaultMessage: "Réinitialiser" }) }),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("small", null,
                    React.createElement(Link, { to: "/authentification" },
                        React.createElement(FormattedMessage, { id: "Annuler", defaultMessage: "Annuler" }))),
                React.createElement("div", { style: { display: passwordRenewed ? 'block' : 'none' } },
                    React.createElement("small", null,
                        React.createElement(FormattedMessage, { id: "L'email vous a \u00E9t\u00E9 envoy\u00E9", defaultMessage: "L'email vous a \u00E9t\u00E9 envoy\u00E9" })))))));
}
export default Initialize;
