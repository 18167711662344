import { extractResponseData, extractResponseErrors } from 'common';
import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { fetchPudosFailure, fetchPudosSuccess, fetchShippingFeesFailure, fetchShippingFeesSuccess } from './shippingsActions';
import { ShippingActionTypes } from './shippingsTypes';
function* fetchShippingsPrices() {
    while (true) {
        const action = yield take(ShippingActionTypes.FETCH_SHIPPING_FEES_ATTEMPT);
        const { response, error } = yield call(api.shippings.fetchShippingFees, action.payload);
        if (response)
            yield put(fetchShippingFeesSuccess(extractResponseData(response)));
        else
            yield put(fetchShippingFeesFailure(extractResponseErrors(error)));
    }
}
function* fetchPudos() {
    while (true) {
        const action = yield take(ShippingActionTypes.FETCH_PUDOS_ATTEMPT);
        const { response, error } = yield call(api.shippings.fetchPudos, action.payload);
        if (response)
            yield put(fetchPudosSuccess(extractResponseData(response)));
        else
            yield put(fetchPudosFailure(extractResponseErrors(error)));
    }
}
export function* shippingsSagas() {
    yield all([
        fork(fetchShippingsPrices),
        fork(fetchPudos)
    ]);
}
