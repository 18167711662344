import { toggleAddToCartAnimationAction, prevalidateCartSuccess, prevalidateCartFailure, verifyCartPromotionSuccess, verifyCartPromotionFailure, fetchUserPromotionFailure, fetchUserPromotionSuccess, togglePromotionOpen, prevalidateCartToQuotationSuccess, prevalidateCartToQuotationFailure } from './cartActions';
import { END, eventChannel } from 'redux-saga';
import { take, fork, all, call, takeLatest, put } from 'redux-saga/effects';
import { CartActionTypes } from './cartTypes';
import api from 'services/api';
import { extractResponseData, extractResponseErrors } from 'common';
import { push } from 'connected-react-router';
function countdown(secs) {
    return eventChannel(emitter => {
        const iv = setInterval(() => {
            secs -= 1;
            secs > 0 ? emitter(secs) : emitter(END);
        }, 1000);
        return () => {
            clearInterval(iv);
        };
    });
}
function* startCountdown() {
    const chan = yield call(countdown, 2);
    yield put(toggleAddToCartAnimationAction(true));
    try {
        while (true) {
            yield take(chan);
        }
    }
    finally {
        yield put(toggleAddToCartAnimationAction(false));
    }
}
function* addToCart() {
    yield takeLatest([CartActionTypes.ADD_TO_CART, CartActionTypes.EDIT_TO_CART], startCountdown);
}
function* prevalidateCart() {
    while (true) {
        const action = yield take(CartActionTypes.PREVALIDATE_CART_ATTEMPT);
        const { response, error } = yield call(api.cart.prevalidate, action.payload);
        if (response) {
            yield put(prevalidateCartSuccess(extractResponseData(response)));
            yield put(push('/commande'));
        }
        else {
            yield put(prevalidateCartFailure(extractResponseErrors(error)));
        }
    }
}
function* verifyCartPromotion() {
    while (true) {
        const action = yield take(CartActionTypes.VERIFY_CART_PROMOTION_ATTEMPT);
        const { response, error } = yield call(api.cart.verifyPromotion, action.payload);
        if (response) {
            yield put(verifyCartPromotionSuccess(extractResponseData(response)));
            yield put(togglePromotionOpen(false));
        }
        else {
            yield put(verifyCartPromotionFailure(extractResponseErrors(error)));
        }
    }
}
function* fetchUserPromotion() {
    while (true) {
        const action = yield take(CartActionTypes.FETCH_USER_PROMOTION_ATTEMPT);
        const { response, error } = yield call(api.cart.fetchUserPromotion, action.payload);
        if (response) {
            yield put(fetchUserPromotionSuccess(extractResponseData(response)));
        }
        else {
            yield put(fetchUserPromotionFailure(extractResponseErrors(error)));
        }
    }
}
function* prevalidateCartToQuotation() {
    while (true) {
        const action = yield take(CartActionTypes.PREVALIDATE_CART_TO_QUOTATION_ATTEMPT);
        const { response, error } = yield call(api.cart.prevalidate, action.payload);
        if (response) {
            yield put(prevalidateCartToQuotationSuccess(extractResponseData(response)));
            yield put(push('/devis'));
        }
        else {
            yield put(prevalidateCartToQuotationFailure(extractResponseErrors(error)));
        }
    }
}
export function* cartSagas() {
    yield all([
        fork(addToCart),
        fork(prevalidateCart),
        fork(verifyCartPromotion),
        fork(fetchUserPromotion),
        fork(prevalidateCartToQuotation),
    ]);
}
