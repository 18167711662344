import { OrdersActionTypes } from "./ordersTypes";
export const fetchOrdersAttempt = () => ({
    type: OrdersActionTypes.FETCH_ORDERS_ATTEMPT,
});
export const fetchOrdersSuccess = (data) => ({
    type: OrdersActionTypes.FETCH_ORDERS_SUCCESS,
    payload: data
});
export const fetchOrdersFailure = (errors) => ({
    type: OrdersActionTypes.FETCH_ORDERS_FAILURE,
    payload: errors
});
