import { fetchProductsSuccess, fetchProductsFailure, fetchProductSuccess, fetchProductFailure, fetchColorChartSuccess, fetchColorChartFailure } from './productActions';
import { call, put, take, fork, all } from 'redux-saga/effects';
import api from 'services/api';
import { ProductActionTypes } from './productTypes';
import { extractResponseData, extractResponseErrors } from 'common';
function* fetchProducts() {
    while (true) {
        yield take(ProductActionTypes.FETCH_PRODUCTS_ATTEMPT);
        const { response, error } = yield call(api.products.findAll);
        if (response)
            yield put(fetchProductsSuccess(extractResponseData(response)));
        else {
            yield put(fetchProductsFailure(extractResponseErrors(error)));
        }
    }
}
function* fetchProduct() {
    while (true) {
        const action = yield take(ProductActionTypes.FETCH_PRODUCT_ATTEMPT);
        const { response, error } = yield call(api.products.findOne, action.payload);
        if (response)
            yield put(fetchProductSuccess(extractResponseData(response)));
        else {
            yield put(fetchProductFailure(extractResponseErrors(error)));
        }
    }
}
function* fetchColorChart() {
    while (true) {
        yield take(ProductActionTypes.FETCH_COLOR_CHART_ATTEMPT);
        const { response, error } = yield call(api.products.findColorChart);
        if (response)
            yield put(fetchColorChartSuccess(extractResponseData(response)));
        else {
            yield put(fetchColorChartFailure(extractResponseErrors(error)));
        }
    }
}
export function* productsSagas() {
    yield all([
        fork(fetchProducts),
        fork(fetchProduct),
        fork(fetchColorChart),
    ]);
}
