import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchColorChartsFailure, fetchColorChartsSuccess } from "./colorChartsActions";
import { ColorChartsActionTypes } from "./colorChartsTypes";
function* fetchColorCharts() {
    while (true) {
        yield take(ColorChartsActionTypes.FETCH_COLORCHARTS_ATTEMPT);
        const { response, error } = yield call(api.colorCharts.findAll);
        if (response)
            yield put(fetchColorChartsSuccess(extractResponseData(response)));
        else
            yield put(fetchColorChartsFailure(extractResponseErrors(error)));
    }
}
export function* colorChartsSagas() {
    yield all([
        fork(fetchColorCharts),
    ]);
}
