import instance from "./instance";
export const quotation = (values) => {
    return instance.post(`/quotations`, values)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
};
export const getQuotations = () => {
    return instance.get(`/quotations`)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
};
