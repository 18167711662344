import React from "react";
import { connect } from 'react-redux';
import Initialize from './Initialize';
import Recover from "./Recover";
function Recovery({ query }) {
    return (React.createElement("div", { className: "auth container", style: { margin: 'auto', marginTop: '150px', padding: '20px', marginBottom: '200px', border: 'solid black 3px', maxWidth: '500px', width: '90%' } },
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement("div", { className: "content" }, query.token
                    ? React.createElement(Recover, null)
                    : React.createElement(Initialize, null))))));
}
const mapStateToProps = (state) => ({
    query: state.router.location.query
});
export default connect(mapStateToProps)(Recovery);
