import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchRealisationFailure, fetchRealisationsSuccess } from "./realisationsActions";
import { RealisationsActionTypes } from "./realisationsTypes";
function* fetchRealisations() {
    while (true) {
        yield take(RealisationsActionTypes.FETCH_REALISATIONS_ATTEMPT);
        const { response, error } = yield call(api.realisations.findAll);
        if (response)
            yield put(fetchRealisationsSuccess(extractResponseData(response)));
        else
            yield put(fetchRealisationFailure(extractResponseErrors(error)));
    }
}
export function* realisationsSagas() {
    yield all([
        fork(fetchRealisations),
    ]);
}
