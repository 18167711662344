import { BannerActionTypes } from "./bannerTypes";
const initialState = {
    infos: {}
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BannerActionTypes.FETCH_BANNER_SUCCESS:
            return Object.assign(Object.assign({}, state), { infos: action.payload });
        default:
            return state;
    }
};
export { reducer as bannerReducer };
