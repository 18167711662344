import * as auth from './auth';
import * as answers from './answers';
import * as users from './users';
import * as products from './products';
import * as tags from './tags';
import * as addresses from './addresses';
import * as cart from './cart';
import * as videos from './videos';
import * as documents from './documents';
import * as shippings from './shippings';
import * as order from './order';
import * as home from './home';
import * as realisations from './realisations';
import * as contents from './contents';
import * as colorCharts from './colorCharts';
import * as banner from './banner';
import * as quotation from './quotation';
import * as filters from './filters';
import * as credits from './credits';
import * as translations from './translations';
export default {
    auth,
    answers,
    users,
    products,
    tags,
    addresses,
    cart,
    videos,
    documents,
    shippings,
    order,
    home,
    realisations,
    contents,
    colorCharts,
    banner,
    quotation,
    filters,
    credits,
    translations
};
