import { TagsActionTypes } from "./tagTypes";
const initialState = {
    list: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TagsActionTypes.FETCH_TAGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: action.payload
                    // .filter((tag: ITag) => !!tag.isDisplayable)
                    .map((tag) => {
                    return Object.assign(Object.assign({}, tag), { active: false });
                }) });
        case TagsActionTypes.SET_ACTIVE_TAG:
            return Object.assign(Object.assign({}, state), { list: state.list
                    .map((tag) => {
                    return Object.assign(Object.assign({}, tag), { active: (tag._id === action.payload) ? true : false });
                }) });
        default:
            return state;
    }
};
export { reducer as tagReducer };
