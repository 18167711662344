import { selectActiveTag, selectTags } from '../tags/tagSelectors';
import { createSelector } from 'reselect';
export const selectProductsState = createSelector((state) => state.products, products => products);
export const selectProductsWithLeaves = createSelector(selectProductsState, (products) => products.list);
export const selectProducts = createSelector(selectProductsWithLeaves, selectTags, (products, tags) => products === null || products === void 0 ? void 0 : products.filter(product => {
    return !product.tag || tags.findIndex(tag => tag._id === product.tag) !== -1;
}));
export const selectProductsFiltered = createSelector(selectProducts, selectActiveTag, (products, tag) => {
    return !!tag
        ? products === null || products === void 0 ? void 0 : products.filter((product) => product.tag === tag._id) : products;
});
export const selectProduct = (slug) => {
    return createSelector(selectProductsWithLeaves, products => (products === null || products === void 0 ? void 0 : products.find((product) => (product === null || product === void 0 ? void 0 : product.slug) === slug)) || null);
};
export const selectColorChart = createSelector(selectProductsState, (products) => products.colorChart);
