import { TagsActionTypes } from "./tagTypes";
export const fetchTagsAttempt = () => ({
    type: TagsActionTypes.FETCH_TAGS_ATTEMPT,
});
export const fetchTagsSuccess = (data) => ({
    type: TagsActionTypes.FETCH_TAGS_SUCCESS,
    payload: data
});
export const fetchTagsFailure = (errors) => ({
    type: TagsActionTypes.FETCH_TAGS_FAILURE,
    payload: errors
});
export const setActiveTag = (tagId) => ({
    type: TagsActionTypes.SET_ACTIVE_TAG,
    payload: tagId
});
