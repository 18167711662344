import Img from "components/Img/Img";
import { Decrement, Increment } from "components/Quantity";
import React, { useEffect } from "react";
import { isFreeChoicesStockValid, isStockValid } from "common";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsWithLeaves } from "store/products/productSelectors";
export function ProductFreeChoiceItem({ increment, decrement, choice }) {
    const dispatch = useDispatch();
    const products = useSelector(selectProductsWithLeaves);
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: `choice-item ${choice.quantity > 0 ? "active" : ""}` },
        React.createElement("div", { onClick: isStockValid(choice) ? increment : undefined },
            choice.imageUrl instanceof Array
                ? React.createElement(Img, { src: `${choice.imageUrl[0]}`, width: 100, height: 100, name: `${choice.name}` })
                : React.createElement(Img, { src: `${choice.imageUrl}`, width: 100, height: 100, name: `${choice.name}` }),
            React.createElement("div", null,
                React.createElement("span", null, choice.name))),
        React.createElement("div", { className: "item-quantity inverse" },
            React.createElement(Decrement, { decrement: decrement }),
            React.createElement("span", null, !isFreeChoicesStockValid(choice, products) && choice.quantity ? choice.quantity + ' dont ' + (choice.quantity - (choice.stock > 0 ? choice.stock : 0)) + ' reliquat(s)' : choice.quantity || 0),
            React.createElement(Increment, { increment: increment }))));
}
