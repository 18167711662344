import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerAttempt } from "store/banner/bannerActions";
import { selectBanner } from "store/banner/bannerSelectors";
import "./Banner.scss";
function Banner() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const banner = useSelector(selectBanner);
    useEffect(() => {
        dispatch(fetchBannerAttempt());
    }, [dispatch]);
    return (React.createElement(React.Fragment, null, !!banner && !!banner.content &&
        React.createElement("div", { className: "banner", dangerouslySetInnerHTML: { __html: banner.content } })));
}
export default Banner;
