import { extractResponseData, extractResponseErrors } from "common";
import { all, call, fork, put, take } from "redux-saga/effects";
import api from "services/api";
import { fetchTranslationsFailure, fetchTranslationsSuccess } from "./translationsActions";
import { TranslationsActionTypes } from "./translationsTypes";
function* fetchTranslations() {
    while (true) {
        const action = yield take(TranslationsActionTypes.FETCH_TRANSLATION_ATTEMPT);
        const { response, error } = yield call(api.translations.findOne, action.payload);
        if (response)
            yield put(fetchTranslationsSuccess(extractResponseData(response)));
        else
            yield put(fetchTranslationsFailure(extractResponseErrors(error)));
    }
}
export function* translationsSagas() {
    yield all([
        fork(fetchTranslations),
    ]);
}
