import { selectIsModalOpen } from 'store/modal/modalSelectors';
import { toggleModal, closeModal } from 'store/modal/modalActions';
import { useDispatch, useSelector } from 'react-redux';
const useModal = () => {
    const dispatch = useDispatch();
    return {
        isModalOpen: (id) => useSelector(selectIsModalOpen(id)),
        toggleModal: (id) => dispatch(toggleModal(id)),
        closeModal: (id) => dispatch(closeModal(id))
    };
};
export default useModal;
