import { ModalsActionTypes } from "./modalTypes";
export const registerModal = (id) => ({
    type: ModalsActionTypes.REGISTER_MODAL,
    payload: id
});
export const unregisterModal = (id) => ({
    type: ModalsActionTypes.UNREGISTER_MODAL,
    payload: id
});
export const toggleModal = (id) => ({
    type: ModalsActionTypes.TOGGLE_MODAL,
    payload: id
});
export const closeModal = (id) => ({
    type: ModalsActionTypes.CLOSE_MODAL,
    payload: id
});
