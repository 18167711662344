import { choicesLeft, choicesScaleLeft, ProductType } from "common";
import React from "react";
import { FormattedMessage } from "react-intl";
export function ChoicesLeft({ product }) {
    const isChoicesOverflow = () => {
        if (product.type === ProductType.CHOICE_PRODUCT && !product.restricted) {
            return false;
        }
        if (product.type === ProductType.SCALE_GROUP) {
            return choicesScaleLeft(product) < 0;
        }
        return choicesLeft(product) < 0;
    };
    return (React.createElement("div", { className: "choices-left" },
        choicesLeft(product) > 0 && product.type !== ProductType.SCALE_GROUP && (React.createElement("p", null,
            choicesLeft(product),
            " ",
            React.createElement(FormattedMessage, { id: "nuances \u00E0 choisir !", defaultMessage: "nuances \u00E0 choisir !" }))),
        isChoicesOverflow() && (React.createElement("p", null,
            React.createElement(FormattedMessage, { id: "Vous avez s\u00E9lectionn\u00E9 trop de nuances !", defaultMessage: "Vous avez s\u00E9lectionn\u00E9 trop de nuances !" })))));
}
