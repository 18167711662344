import { useState } from "react";
const useStep = (defaultStep) => {
    const [activeStep, setActiveStep] = useState(defaultStep || null);
    return [
        activeStep,
        (position) => {
            if (position === activeStep)
                setActiveStep(defaultStep || null);
            else
                setActiveStep(position);
        }
    ];
};
export default useStep;
