export var CartActionTypes;
(function (CartActionTypes) {
    CartActionTypes["ADD_TO_CART"] = "ADD_TO_CART";
    CartActionTypes["REMOVE_FROM_CART"] = "REMOVE_FROM_CART";
    CartActionTypes["SET_FEES_TO_CART"] = "SET_FEES_TO_CART";
    CartActionTypes["DECREMENT_PRODUCT_QUANTITY"] = "DECREMENT_PRODUCT_QUANTITY";
    CartActionTypes["INCREMENT_PRODUCT_QUANTITY"] = "INCREMENT_PRODUCT_QUANTITY";
    CartActionTypes["TOGGLE_ADD_TO_CART_ANIMATION"] = "TOGGLE_ADD_TO_CART_ANIMATION";
    CartActionTypes["TOGGLE_PROMOTION_OPEN"] = "TOGGLE_PROMOTION_OPEN";
    CartActionTypes["PREVALIDATE_CART_ATTEMPT"] = "PREVALIDATE_CART_ATTEMPT";
    CartActionTypes["PREVALIDATE_CART_SUCCESS"] = "PREVALIDATE_CART_SUCCESS";
    CartActionTypes["PREVALIDATE_CART_FAILURE"] = "PREVALIDATE_CART_FAILURE";
    CartActionTypes["VERIFY_CART_PROMOTION_ATTEMPT"] = "VERIFY_CART_PROMOTION_ATTEMPT";
    CartActionTypes["VERIFY_CART_PROMOTION_SUCCESS"] = "VERIFY_CART_PROMOTION_SUCCESS";
    CartActionTypes["VERIFY_CART_PROMOTION_FAILURE"] = "VERIFY_CART_PROMOTION_FAILURE";
    CartActionTypes["FETCH_USER_PROMOTION_ATTEMPT"] = "FETCH_USER_PROMOTION_ATTEMPT";
    CartActionTypes["FETCH_USER_PROMOTION_SUCCESS"] = "FETCH_USER_PROMOTION_SUCCESS";
    CartActionTypes["FETCH_USER_PROMOTION_FAILURE"] = "FETCH_USER_PROMOTION_FAILURE";
    CartActionTypes["REMOVE_PROMOTION_FROM_CART"] = "REMOVE_PROMOTION_FROM_CART";
    CartActionTypes["RESET_CART"] = "RESET_CART";
    CartActionTypes["SELECT_PRODUCT_TO_MODIFY"] = "SELECT_PRODUCT_TO_MODIFY";
    CartActionTypes["EDIT_TO_CART"] = "EDIT_TO_CART";
    CartActionTypes["PREVALIDATE_CART_TO_QUOTATION_ATTEMPT"] = "PREVALIDATE_CART_TO_QUOTATION_ATTEMPT";
    CartActionTypes["PREVALIDATE_CART_TO_QUOTATION_SUCCESS"] = "PREVALIDATE_CART_TO_QUOTATION_SUCCESS";
    CartActionTypes["PREVALIDATE_CART_TO_QUOTATION_FAILURE"] = "PREVALIDATE_CART_TO_QUOTATION_FAILURE";
})(CartActionTypes || (CartActionTypes = {}));
