import { all, fork, put, take } from "@redux-saga/core/effects";
import { extractResponseData, extractResponseErrors } from "common";
import { call } from "redux-saga/effects";
import api from "services/api";
import { fetchQuotationsFailure, fetchQuotationsSuccess } from "./quotationsActions";
import { QuotationsActionTypes } from "./quotationsTypes";
function* fetchQuotations() {
    while (true) {
        yield take(QuotationsActionTypes.FETCH_QUOTATIONS_ATTEMPT);
        const { response, error } = yield call(api.quotation.getQuotations);
        if (response)
            yield put(fetchQuotationsSuccess(extractResponseData(response)));
        else
            yield put(fetchQuotationsFailure(extractResponseErrors(error)));
    }
}
export function* quotationsSagas() {
    yield all([
        fork(fetchQuotations),
    ]);
}
