import App from './App';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore, history } from 'store/store';
import { ConnectedRouter } from 'connected-react-router';
import { createExpirationInterceptor, logoutAttempt, updateExpiration } from 'common';
import instance from 'services/api/instance';
import ScrollToTop from 'shared/components/ScrollToTop';
const store = configureStore(window.__PRELOADED_STATE__ || {});
delete window.__PRELOADED_STATE__;
instance.interceptors.response.use(...createExpirationInterceptor((exp) => store.dispatch(updateExpiration(exp))));
instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store.dispatch(logoutAttempt());
    }
    return Promise.reject(error);
});
hydrate(React.createElement(Provider, { store: store },
    React.createElement(ConnectedRouter, { history: history },
        React.createElement(ScrollToTop, { history: history },
            React.createElement(App, null)))), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
