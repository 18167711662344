import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./saga";
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
const history = typeof document !== 'undefined'
    ? createBrowserHistory()
    : createMemoryHistory();
const configureStore = (preloadedState = null) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(rootReducer(history), preloadedState, composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))));
    sagaMiddleware.run(rootSaga);
    // this piece of code enables hot reload of state with redux
    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./reducer', () => {
            store.replaceReducer(require('./reducer').default);
        });
    }
    return store;
};
export { history, configureStore };
