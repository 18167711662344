import React from "react";
import { ProductFreeChoiceItem } from "./ProductFreeChoiceItem";
import { getFreeChoices } from "common";
export function ProductFreeChoices({ form, replace }) {
    var _a;
    const increase = (index, choice) => {
        var _a, _b;
        const maxCount = ((_a = getFreeChoices(form.values)) === null || _a === void 0 ? void 0 : _a.count) || 0;
        const currentCount = ((_b = getFreeChoices(form.values)) === null || _b === void 0 ? void 0 : _b.ids.reduce((acc, curr) => {
            acc += curr.quantity || 0;
            return acc;
        }, 0)) || 0;
        if (currentCount < maxCount)
            replace(index, Object.assign(Object.assign({}, choice), { quantity: (choice.quantity || 0) + 1 }));
    };
    const decrease = (index, choice) => {
        if (choice.quantity > 0)
            replace(index, Object.assign(Object.assign({}, choice), { quantity: (choice.quantity || 0) - 1 }));
    };
    return (React.createElement(React.Fragment, null, (_a = getFreeChoices(form.values)) === null || _a === void 0 ? void 0 : _a.ids.map((choice, index) => {
        return React.createElement(ProductFreeChoiceItem, { key: choice._id, increment: () => increase(index, choice), decrement: () => decrease(index, choice), choice: choice });
    })));
}
