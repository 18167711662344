import React from "react";
import { FormattedMessage as BaseFormattedMessage } from "react-intl";
export const FormattedMessage = (props) => {
    const getId = () => {
        var _a;
        const id = (_a = props === null || props === void 0 ? void 0 : props.id) === null || _a === void 0 ? void 0 : _a.replace(/ /g, '');
        if (!id) {
            console.warn("No ID for FormattedMessage tag");
            return '-';
        }
        return id;
    };
    return (React.createElement(BaseFormattedMessage, Object.assign({}, props, { id: getId() })));
};
