import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { AnswersActionTypes } from './answerTypes';
import { fetchAnswersSuccess, fetchAnswersFailure } from './answerActions';
import { extractResponseData, extractResponseErrors } from 'common';
function* fetchAnswers() {
    while (true) {
        yield take(AnswersActionTypes.FETCH_ANSWERS_ATTEMPT);
        const { response, error } = yield call(api.answers.findAll);
        if (response)
            yield put(fetchAnswersSuccess(extractResponseData(response)));
        else
            yield put(fetchAnswersFailure(extractResponseErrors(error)));
    }
}
export function* answersSagas() {
    yield all([
        fork(fetchAnswers),
    ]);
}
