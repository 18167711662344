import { all, call, fork, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { TagsActionTypes } from './tagTypes';
import { fetchTagsSuccess, fetchTagsFailure } from './tagActions';
import { extractResponseData, extractResponseErrors } from 'common';
function* fetchTags() {
    while (true) {
        yield take(TagsActionTypes.FETCH_TAGS_ATTEMPT);
        const { response, error } = yield call(api.tags.findAll);
        if (response)
            yield put(fetchTagsSuccess(extractResponseData(response)));
        else
            yield put(fetchTagsFailure(extractResponseErrors(error)));
    }
}
export function* tagsSagas() {
    yield all([
        fork(fetchTags),
    ]);
}
