import { AnswersActionTypes } from "./answerTypes";
export const fetchAnswersAttempt = () => ({
    type: AnswersActionTypes.FETCH_ANSWERS_ATTEMPT,
});
export const fetchAnswersSuccess = (data) => ({
    type: AnswersActionTypes.FETCH_ANSWERS_SUCCESS,
    payload: data
});
export const fetchAnswersFailure = (errors) => ({
    type: AnswersActionTypes.FETCH_ANSWERS_FAILURE,
    payload: errors
});
