import { OrdersActionTypes } from "./ordersTypes";
const initialState = {
    list: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: action.payload });
        default:
            return state;
    }
};
export { reducer as ordersReducer };
