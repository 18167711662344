import instance from "./instance";
export const fetchShippingFees = (data) => {
    return instance.get(`/shippings/fees`, {
        params: data
    })
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const fetchPudos = (data) => {
    return instance.post(`/shippings/pudos`, data)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
