import { HomeActionTypes } from "./homeTypes";
const initialState = {
    infos: {}
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HomeActionTypes.FETCH_HOME_SUCCESS:
            return Object.assign(Object.assign({}, state), { infos: action.payload });
        default:
            return state;
    }
};
export { reducer as homeReducer };
