import { HomeActionTypes } from "./homeTypes";
export const fetchHomeAttempt = (businessType) => ({
    type: HomeActionTypes.FETCH_HOME_ATTEMPT,
    payload: { businessType },
});
export const fetchHomeSuccess = (data) => ({
    type: HomeActionTypes.FETCH_HOME_SUCCESS,
    payload: data,
});
export const fetchHomeFailure = (errors) => ({
    type: HomeActionTypes.FETCH_HOME_FAILURE,
    payload: errors,
});
