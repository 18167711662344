import instance from "./instance";
export const findAll = () => {
    return instance.get(`/products`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const findOne = (slug) => {
    return instance.get(`/products/${slug}`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const findLeaves = () => {
    return instance.get(`/products/leaves`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
export const findColorChart = () => {
    return instance.get(`/products/color-chart`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
};
